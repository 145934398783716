<template>
  <div class="max-w-[600px] mx-auto md:px-0 px-4 pb-[50vh]">
    <h1 class="text-3xl py-8 font-black">Fund Spider <span class="text-sm opacity-50 font-medium">v0.0.0-alpha</span></h1>
    <div>
      <form action="javascript:" class="w-full flex justify-between" @submit="startTaskStreaming">
        <input type="text" class="rounded border border-black mb-4 w-full py-2 px-4 text-sm h-8" required
               placeholder="基金公司代码" v-model="fund_code">
        <button class="rounded bg-black text-white px-4 shrink-0 h-8 leading-8 text-sm ml-4">开始</button>
      </form>
    </div>
    <div class="overflow-auto">
      <div v-for="task in tasks" class="flex mb-2 flex-wrap" :key="task.id">
        <div class="text-xs opacity-70 w-full mb-1" v-show="task.id !== 0">
          {{ dayjs(task.id).format('MM-DD HH:mm:ss.SSS') }}
        </div>
        <div class="w-full flex">
          <div class="relative top-[1px] w-[1.5rem]">
            <IconClockPause :size="16" v-show="task.status === 'pending'"/>
            <IconLoader2 :size="16" v-show="task.status === 'running'" class="animate-spin"/>
            <IconCircleCheckFilled :size="16" v-show="task.status === 'done'"/>
            <IconExclamationCircle :size="16" v-show="task.status === 'error'" class="text-red-600"/>
          </div>
          <div class="w-[calc(100% - 1.5rem)]">
            <div class="font-mono text-sm">{{ task.text }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2" v-show="showDownloadButton">
      <button class="rounded bg-black px-4 py-2 text-white text-sm" @click="formPersonsTable">下载</button>
    </div>
  </div>
</template>

<script setup>
import {reactive, ref} from 'vue'
import {IconCircleCheckFilled, IconClockPause, IconExclamationCircle, IconLoader2} from '@tabler/icons-vue'
import $ from 'jquery'
import Papa from 'papaparse'
import dayjs from 'dayjs'

let json = []

let defaultTask = {
  id: 0,
  status: 'pending',
  text: '等待用户开始'
}
let tasks = ref([
  reactive(defaultTask)
])

let formTable = function (data) {
  let table = '<table>'
  let keys = Object.keys(data[0])

  let thead = '<thead><tr>'
  keys.forEach(key => {
    thead += `<th>${key}</th>`
  })
  thead += '</tr></thead>'

  let tbody = '<tbody>'
  data.forEach(row => {
    tbody += '<tr>'
    keys.forEach(key => {
      tbody += `<td>${row[key]}</td>`
    })
    tbody += '</tr>'
  })

  tbody += '</tbody>'

  table += thead + tbody + '</table>'

  return table
}

let formPersonsTable = function () {
  let personJson = []
  json.forEach(person => {
    let obj = {
      '姓名': person.name,
      '介绍': person.person_info,
      '管理基金': '',
      '基金持仓': '',
      '股票持仓': '',
      '债券持仓': ''
    }

    // 管理的基金
    person.data.forEach(row => {
      obj['管理基金'] += `${row['基金名称']}(${row['基金代码']}) - ${row['类型']};\n`

      // 持仓
      let position = person.position.find(item => item.id === row['基金代码'])

      if (position) {
        obj['基金持仓'] += `\n\n${row['基金名称']}: \n`
        obj['股票持仓'] += `\n\n${row['基金名称']}: \n`
        obj['债券持仓'] += `\n\n${row['基金名称']}: \n`
        position.position_data.forEach(p_data => {
          if (p_data.type === '基金持仓') {
            p_data.data.forEach(cell => {
              obj['基金持仓'] += `${cell['基金名称']};\n`
            })
          }

          if (p_data.type === '股票持仓') {
            p_data.data.forEach(cell => {
              obj['股票持仓'] += `${cell['股票名称']};\n`
            })
          }

          if (p_data.type === '债券持仓') {
            p_data.data.forEach(cell => {
              obj['债券持仓'] += `${cell['债券名称']};\n`
            })
          }
        })
      }
    })
    personJson.push(obj)
  })

  let csv = Papa.unparse(personJson, {
    newline: '\n'
  })

  // download
  let blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'})
  let link = document.createElement('a')
  let url = URL.createObjectURL(blob)

  link.setAttribute('href', url)
  link.setAttribute('download', `${fund_code.value}.csv`)

  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
}

let showDownloadButton = ref(false)
let processGotError = ref(false)
let startTaskStreaming = async function () {
  json = []
  showDownloadButton.value = false
  processGotError.value = false
  tasks.value = [reactive(defaultTask)]
  let html = await task1()
  if (processGotError.value) {
    return false
  }
  await task2(html)
  if (processGotError.value) {
    return false
  }
  await task3()
  if (processGotError.value) {
    return false
  }
  await task4()
  if (processGotError.value) {
    return false
  }
  await task5()
  if (processGotError.value) {
    return false
  }

  showDownloadButton.value = true
}

let scrollDown = function () {
  let frame = document.querySelector('html')
  frame.scroll({
    top: frame.scrollHeight - frame.clientHeight,
    behavior: 'instant'
  })
}

const api_base = location.hostname === 'localhost' ? '/api' : ''

let setTaskStatus = function (id, status, text = null) {
  let currentTask = tasks.value.find(item => item.id === id)
  currentTask.status = status

  if (text) {
    currentTask.text = text
  }

  scrollDown()
}

const domParser = new DOMParser()

// get page
let fund_code = ref('')
const task1 = async function () {
  let id = Date.now()
  let task = {
    id: id,
    status: 'running',
    text: '下载页面'
  }

  tasks.value.push(reactive(task))

  let resp

  try {
    resp = await fetch(api_base + '/proxy', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        url: `https://fund.eastmoney.com/Company/f10/jjjl_${fund_code.value}.html`
      })
    })
    resp = await resp.text()
  } catch (e) {
    console.log(e)
    processGotError.value = true
    setTaskStatus(id, 'error')
    return
  }

  setTaskStatus(id, 'done')

  return resp
}

let task2 = async function (htmlText) {
  let doc = domParser.parseFromString(htmlText, 'text/html')

  let id = Date.now()
  let task = {
    id,
    status: 'running',
    text: '获取基金经理'
  }

  tasks.value.push(reactive(task))

  $('.common-block-con .table-content', doc).each((index, el) => {
    let obj = {}
    let name = $(el).find('p a').text()
    let person_info_url = $(el).find('p a').attr('href')

    obj.name = name
    obj.person_info_url = person_info_url
    obj.data = []

    let keys = []
    $(el).find('table tbody tr:first-child th').each((i, e) => {
      keys.push($(e).text())
    })

    $(el).find('table tbody tr:not(:first-child)').each((row_index, row) => {
      obj.data[row_index] = {}
      $(row).find('td').each((cell_index, cell) => {
        let key = keys[cell_index]
        if ($(cell).has('a').length) {
          obj.data[row_index][key] = $('a', cell).text()
        } else if ($(cell).has('p').length) {
          obj.data[row_index][key] = $('p', cell).text()
        } else {
          obj.data[row_index][key] = $(cell).text()
        }
      })
    })

    json.push(obj)
  })

  if (json.length === 0) {
    setTaskStatus(id, 'error', '没有找到任何基金经理')
    processGotError.value = true
    return false
  }

  setTaskStatus(id, 'done')
}

let task3 = async function () {
  if (json.length === 0) {
    return false
  }

  let id = Date.now()
  let task = {
    id,
    status: 'running',
    text: '获取基金经理个人介绍...'
  }

  tasks.value.push(reactive(task))

  let successCount = 0

  for (let i = 0; i < json.length; i++) {
    const el = json[i]

    let endpoint = el.person_info_url

    let html = await fetch(api_base + '/proxy', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        url: endpoint
      })
    }).then(res => res.text()).catch(e => {
      processGotError.value = true
      setTaskStatus(id, 'error')
      return ''
    })

    if (!html) {
      throw new Error('html is empty')
    }

    let doc = domParser.parseFromString(html, 'text/html')

    $('.jlinfo .right p span', doc).remove()

    json[i].person_info = $('.jlinfo .right p', doc).text().trim()
    successCount++

    setTaskStatus(id, 'running', `获取基金经理个人介绍...(${successCount}/${json.length})`)
  }

  setTaskStatus(id, 'done', `获取基金经理个人介绍...(${successCount}/${json.length})`)

  console.log(json)
}

let task4 = async function () {
  for (let i = 0; i < json.length; i++) {
    const person = json[i]
    let row_data = person.data

    console.log('getting position for', person.name, '...')

    person.position = []

    for (let j = 0; j < row_data.length; j++) {
      const row = row_data[j]

      let id = row['基金代码']
      let name = row['基金名称']

      let task_id = Date.now()
      let task = {
        id: task_id,
        status: 'running',
        text: `获取基金【${name}】信息`
      }

      tasks.value.push(reactive(task))

      console.log('getting position for ', name, '...')

      let html = await fetch(api_base + '/proxy', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          url: `https://fund.eastmoney.com/${id}.html`
        })
      }).then(res => res.text()).catch(e => {
        processGotError.value = true
        setTaskStatus(task_id, 'error')
        return ''
      })

      if (!html) {
        throw new Error('html is empty')
      }

      let doc = domParser.parseFromString(html, 'text/html')

      let position_data = []

      $('#quotationItem_DataTable .hd .titleItems', doc).each((index, el) => {
        let type = $(el).find('h3 a').text().trim()
        position_data.push({
          type: type
        })
      })

      $('#quotationItem_DataTable .bd ul li', doc).each((bd_index, el) => {
        let table = $(el).find('table')
        let th_length = 0
        position_data[bd_index].data = []
        let tr_keys = []
        $(table).find('tr:first-child th').each((key_index, th) => {
          let k = $(th).text().trim()
          tr_keys.push(k)
          th_length++
        })

        $(table).find('tr:not(:first-child)').each((value_index, tr) => {
          let tds = $(tr).find('td')

          let obj = {}

          $(tds).each((td_index, td) => {
            if (td_index >= tr_keys.length) {
              return
            }

            let text = ''
            if ($(td).has('a').length) {
              let a = $(td).find('a')
              text = a.attr('title')
            } else {
              text = $(td).text().trim()
            }

            let key = tr_keys[td_index]
            obj[key] = text
          })

          position_data[bd_index].data.push(obj)
        })
      })

      person.position.push({
        id: id,
        name: name,
        position_data: position_data
      })

      setTaskStatus(task_id, 'done')
    }

    json[i] = person
  }

  console.log(json)
}

let industryConfig = []
let fundCodesMap = {}

let task5 = async function () {
  for (const person of json) {
    for (const el of person.data) {
      let code = el['基金代码']
      let name = el['基金名称']

      fundCodesMap[code] = name
    }
  }

  let codes = Object.keys(fundCodesMap)

  for (const code of codes) {
    let codeIndex = codes.indexOf(code)

    let obj = {
      code,
      name: fundCodesMap[code],
      industry: ''
    }

    let resp, data

    console.log('getting', code, codeIndex, '...')

    let task_id = Date.now()
    let task = {
      id: task_id,
      status: 'running',
      text: `获取重仓行业【${fundCodesMap[code]}】`
    }

    tasks.value.push(reactive(task))

    try {
      resp = await fetch(api_base + `/industryConfig`, {
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({
          code
        })
      })

      data = await resp.json()
    } catch (e) {
      console.log(e)
      processGotError.value = true
      setTaskStatus(task_id, 'error')

      data = null
    }

    if (!data) {
      throw new Error('data is empty')
    }

    if (data.Data && data.Data.QuarterInfos && data.Data.QuarterInfos.length > 0) {
      for (let i = 0; i < 10; i++) {
        let item = data.Data.QuarterInfos[0].HYPZInfo[i]
        if (!item) {
          continue
        }
        obj.industry += `${item.HYMC};\n`
      }
    } else {
      obj.industry = '/'
    }

    setTaskStatus(task_id, 'done')

    industryConfig.push(obj)
  }
}

</script>
